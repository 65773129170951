html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #1F1D2B; /* Ensure this matches your theme's primary background color */
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Home-logo {
  min-width: 450px;
  max-width: 450px;
  width: calc(40% - 600px);
  height: auto; /* Maintain aspect ratio */
  padding-top: 10%;
}

/* For smaller screens */
@media (max-width: 1170px) {
  .Home-logo {
    min-width: 400px;
    max-width: 400px;
    width: calc(60% - 30px); /* Increase the size for tablets */
    height: auto;
    padding-top: 8%;
  }
}

/* For very small screens, like phones */
@media (max-width: 768px) {
  .Home-logo {
    min-width: 300px;
    max-width: 300px;
    width: calc(70% - 20px); /* Increase the size for phones */
    height: auto;
    padding-top: 4%;
  }
}

.Account {
  font-size: 0.8em;
  margin-top: 3px;
  margin-left: 50px;
}

/* For smaller screens */
@media (max-width: 1170px) {
  .Account {
      font-size: 0.8em;
      margin-top: 3px;
      margin-left: 40px;
  }
}

/* For very small screens, like phones */
@media (max-width: 768px) {
  .Account {
      font-size: 0.8em;
      margin-top: 3px;
      margin-left: 20px;
  }
}

